@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('./recaptcha.css');

* {
  font-family: 'Roboto', sans-serif;

  @media (max-width: 526px) {
    cursor: inherit !important;
  }
}

html {
  width: 100%;
  height: 100%;
  line-height: unset !important;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: #f2f2f2;
  background-size: cover;
  line-height: unset !important;
  overflow: hidden;
  overscroll-behavior: none;
}

.be-frame-minimized {
  display: none;
}

#root {
  height: 100%;
  overflow: hidden auto;
  overscroll-behavior: none;
  width: 100%;
}

#portal-root {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

#portal-root > * {
  pointer-events: all;
}

::placeholder {
  opacity: 1;
}
